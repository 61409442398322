export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  codigoSistema: '0001',
  timeToRefreshToken: 10000,
  channel: 'web',
  merchantId: '522591303',
  endPointJS: "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true",
  siteKey: '6Le3d78ZAAAAANndqU27yzGL6PdEdY_ZQO4hAaO0',
  privateKey: 'pk_test_YXJ1p5A6CZ3yPRBC',
  apiKeyGOOGLE_MAPS: 'AIzaSyCZM8sPvTxqbzspI4Y4j2Joop7qjR63jE0',
  social: {
    FACEBOOK_ID: '313635033369228',
    GOOGLE_ID: '742815218794-7gf5c9eslqbvalftjipq1vu3097qtd34.apps.googleusercontent.com'
  },

  jwt: {
    audience: 'DefaultAudience',
    issuer: 'DefaultIssuer'
  },

  /*WEB SERVICES*/
  api: {
    WS_IT: {
      url: 'https://api.cosechandoganadores.com:9139/WS_IT/api',//http://danper-31:8989/WS_IT/api
      basicAuthorization: {
        username: 'STAITApp',
        password: '975318642'
      }
    },
    WS_HCM: {
      url: 'https://api.cosechandoganadores.com:9139/WS_HCM/api',//http://danper-31:8989/WS_HCM/api
      basicAuthorization: {
        username: 'VnSDApp',
        password: '975318642'
      }
    }
  },
  firebaseMyDanper: {
    apiKey: "AIzaSyC87kq5KrcTFEb5HoF2bjcezQr90-XsEcU",
    authDomain: "danper-gch.firebaseapp.com",
    databaseURL: "https://danper-gch.firebaseio.com",
    projectId: "danper-gch",
    storageBucket: "danper-gch.appspot.com",
    messagingSenderId: "829250195306",
    appId: "1:829250195306:web:7e9f35a801de2248725f13",
    measurementId: "G-X04BS081F3"
  },
};