import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators'
import { HeaderBasicAuthorizationService } from '../../_shared/services/header-basic-authorization.service';
import { JwtService } from '../../_shared/services/jwt.service';
import { StorageService } from '../../_shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { ApiEnum } from '../../_shared/enums/api.enum';
import { StorageKeyEnum } from '../../_shared/enums/storage-key.enum';
import { PermissionNavigationService } from '../../_shared/services/permission-navigation.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    numberDetails = 0;
    isLoggedSocial = false;
    centroSelected = 0;

    constructor(private router: Router,
        private storageService: StorageService,
        private jwtService: JwtService,
        private httpClient: HttpClient,
        private headerBasicAuthorization: HeaderBasicAuthorizationService,
        private permissionNavigationService: PermissionNavigationService) { }

    login(model) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ZITG_LoginColaborador`, model, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
            .pipe(
                tap((response: any) => {
                    if (response.Ok) {
                        this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                        this.jwtService.load(response.JWT);
                        this.storageService.set("DNI", response.DocIdentidad);
                        this.storageService.set("Email", response.Email);
                        this.storageService.set("Nombre", response.Nombre);
                        this.storageService.set("ApPaterno", response.ApPaterno);
                        this.storageService.set("ApMaterno", response.ApMaterno);
                        this.storageService.set("Puntos", response.Puntos);
                        this.storageService.set("Direccion", response.Direccion);
                        this.storageService.set("Telefono", response.Telefono);
                        this.storageService.set("UsuarioID", response.UsuarioID);
                        this.storageService.set("ActivoPersonal", response.ActivoPersonal);
                    }
                })
            )
    }

    logoff() {
        // this.permissionNavigationService.reset();
        this.storageService.remove(StorageKeyEnum.JWT_AUTHORIZATION);
        this.storageService.remove(StorageKeyEnum.CART);
        this.jwtService.clear();
        localStorage.clear();
    }

    refreshToken() {
        return this.httpClient.get(`${environment.api.WS_IT.url}/Seguridad/RefreshToken`, {
            headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
        }).pipe(
            tap((response: any) => {
                if (response.Ok) {
                    this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                    this.jwtService.load(response.JWT);
                }
            })
        );
    }

    listarEmpresas() {
        return this.httpClient.get(`${environment.api.WS_HCM.url}/Colaborador/ListarEmpresas`, {
            headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM)
        }).pipe(map(data => data));
    }

    isLoggedIn() {
        let jwt: string = this.storageService.get(StorageKeyEnum.JWT_AUTHORIZATION);
        if (jwt != null) {
            this.jwtService.load(jwt);
            return this.jwtService.isValid();
        } else {
            return false;
        }
    }

    redirectToLogin() {
        this.router.navigate(['/Login']);
    }
    redirectToHome() {
        this.router.navigate(['/Home']);
    }

    redirectToMain() {
        this.router.navigate(['/']);
        //this.router.navigate(['/welcome']);
    }

    redirectToNotAllowed() {
        this.router.navigate(['/error/not-allowed']);
    }
}